import { createSignal } from "solid-js";
import { OTPProps } from "./model";

export function OTP({ otp_length = 4, otp_width = 88,onOTPChange }: OTPProps) {
  const otp_arr = Array(otp_length).fill('');
  const onKey = (e)=>{
      const target = e.target;
      const key = e.key.toLowerCase();
      
      if (key == "backspace" || key == "delete") {
          target.value = "";
          const prev = target.previousElementSibling;
          if (prev) {
              prev.focus();
          }
          return;
      }
  }
  const onEnterNumber = (e)=>{
      const target = e.target;
      const val = target.value;
      const idx = target.id;
      if (isNaN(val)) {
            target.value = "";
            return;
        }
        if (val != "") {
            const next = target.nextElementSibling;
            if (next) next.focus();
        }
        otp_arr[idx] = val;
        
        onOTPChange(otp_arr.join(""))
  }
  return (
    <div class="flex gap-20px">
      {otp_arr.map((id,idx) => {
        return <input id={`${idx}`}  onKeyUp={onKey} onInput={onEnterNumber} type="tel" autocomplete="off" maxlength="1" class={`w-${otp_width}px border-b-2 border#p text-76px text-center`} />;
      })}
    </div>
  );
}
